import React, { useState, useEffect } from "react";
import Pay from "assets/checkOut/pay.png";
import {
  Row,
  Col,
  Button,
  Spin,
  Divider,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Space,
  message,
  Modal,
  Checkbox,
} from "antd";
import { apiGetMe, apiGetCarts, apiPostOrder } from "api/api";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import axios from "axios";

const CheckOut = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const open = useSelector((state) => state.charts.isChartOpen);

  const [radio, setRadio] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [me, setMe] = useState({});
  const [carts, setCarts] = useState([]);
  const [isPayLoading, setIsPayLoading] = useState(false); // # 訂單按鈕 loading
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const genderOptions = [
    { value: 1, label: "男" },
    { value: 2, label: "女" },
    { value: 3, label: "其他" },
  ];

  // # 獲取「用戶」資訊
  const fetchMe = async () => {
    setIsLoading(true);
    try {
      const res = await apiGetMe();
      const { content } = res.data;
      setMe(content);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  // # 獲取「購物車」資訊
  const fetchCarts = async () => {
    try {
      const res = await apiGetCarts();
      const { details } = res.data.content;
      setCarts(details);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchMe();
    fetchCarts();
  }, []);

  // # 開關購物車抽屜，重新取得購物車內容
  useEffect(() => {
    fetchCarts();
  }, [open]);

  // # 當購物車數量改變，重新設置 form list數量
  useEffect(() => {
    if (carts.length > 0) {
      carts.map((cart, index) => {
        form.setFieldValue(
          ["detail", index],
          Array.from(Array(cart.amount), (_, x) => {
            return { sourceId: cart.sourceId };
          })
        );
      });
    }
  }, [carts]);

  // # 登出
  const logOut = () => {
    sessionStorage.removeItem("_skyFilm_t");
    setTimeout(() => navigate("/login"));
  };

  // # 付款
  const handlePay = async (value) => {
    setIsPayLoading(true);
    const details = form.getFieldsValue().detail?.flat();
    try {
      const res = await apiPostOrder({ details, paymentMethod: radio });
      console.log("付款", res);
      const { domain, paymentInfo } = res.data.content;
      // window.open(domain);
      accessEcPay(domain, paymentInfo);
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setIsPayLoading(false);
    }
  };

  // # 付款失敗
  const handelPayError = (error) => {
    message.error(error.errorFields[0].errors[0]);
  };

  // # 創建虛擬 form & input
  const createHiddenInput = (form, name, value) => {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = name;
    hiddenField.value = value;
    form.appendChild(hiddenField);
  };

  // # 介接綠界
  const accessEcPay = async (domain, paymentInfo) => {
    const form = document.createElement("form");
    const {
      merchantID,
      merchantTradeNo,
      merchantTradeDate,
      paymentType,
      totalAmount,
      tradeDesc,
      itemName,
      returnURL,
      choosePayment,
      checkMacValue,
      encryptType,
      clientBackURL,
      needExtraPaidInfo,
    } = paymentInfo;
    form.method = "POST";
    form.action = domain;
    createHiddenInput(form, "MerchantID", merchantID);
    createHiddenInput(form, "ClientBackURL", clientBackURL);
    createHiddenInput(form, "MerchantTradeNo", merchantTradeNo);
    createHiddenInput(form, "MerchantTradeDate", merchantTradeDate);
    createHiddenInput(form, "PaymentType", paymentType);
    createHiddenInput(form, "TotalAmount", totalAmount);
    createHiddenInput(form, "TradeDesc", tradeDesc);
    createHiddenInput(form, "ItemName", itemName);
    createHiddenInput(form, "ReturnURL", returnURL);
    createHiddenInput(form, "ChoosePayment", choosePayment);
    createHiddenInput(form, "CheckMacValue", checkMacValue);
    createHiddenInput(form, "EncryptType", encryptType);
    createHiddenInput(form, "NeedExtraPaidInfo", needExtraPaidInfo);
    document.body.appendChild(form);
    form.submit();
    // let bodyFormData = new FormData();
    // const {
    //   merchantID,
    //   merchantTradeNo,
    //   merchantTradeDate,
    //   paymentType,
    //   totalAmount,
    //   tradeDesc,
    //   itemName,
    //   returnURL,
    //   choosePayment,
    //   checkMacValue,
    //   encryptType,
    //   clientBackURL,
    //   needExtraPaidInfo,
    // } = paymentInfo;
    // bodyFormData.append("MerchantID", merchantID);
    // bodyFormData.append("ClientBackURL", clientBackURL);
    // bodyFormData.append("MerchantTradeNo", merchantTradeNo);
    // bodyFormData.append("MerchantTradeDate", merchantTradeDate);
    // bodyFormData.append("PaymentType", paymentType);
    // bodyFormData.append("TotalAmount", totalAmount);
    // bodyFormData.append("TradeDesc", tradeDesc);
    // bodyFormData.append("ItemName", itemName);
    // bodyFormData.append("ReturnURL", returnURL);
    // bodyFormData.append("ChoosePayment", choosePayment);
    // bodyFormData.append("CheckMacValue", checkMacValue);
    // bodyFormData.append("EncryptType", encryptType);
    // bodyFormData.append("NeedExtraPaidInfo", needExtraPaidInfo);
    // const res = await axios.post(`${domain}`, bodyFormData, {
    //   headers: {
    //     withCredentials: true,
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   },
    // });
    // console.log("綠界！！", res);
    // const { data } = res;
    // const winUrl = URL.createObjectURL(new Blob([data], { type: "text/html" }));

    // const newWindow = window.open(winUrl, "win", `screenX=200,screenY=200`);
    // setIsModalOpen(true);
    // setTimeout(() => {
    //   let win = document.getElementById("orderContent");
    //   console.log("WWWWWWW", win);
    // let win = window.open(
    //   "",
    //   "Title",
    //   "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=" +
    //     (window.innerHeight - 400) +
    //     ",left=" +
    //     (window.innerWidth - 840)
    // );

    // win.innerHTML = data;
    // }, 10);
  };

  // # Radio 改變
  const onRadioChange = (e) => {
    setRadio(e.target.value);
  };

  // # 勾選帶入會員資料
  const handleCheck = (e, index, key) => {
    const { name, phone, email } = me;
    const sourceId = form.getFieldValue(["detail", index, key, "sourceId"]);
    form.setFieldValue(["detail", index, key], {
      sourceId,
      name,
      phone,
      email,
    });
  };

  return (
    <div className="w-full bg-white">
      <Spin spinning={isLoading} size="large" fullscreen />
      <Row>
        <Col
          sm={{ span: 12 }}
          xs={{ span: 24 }}
          className="bg-white pb-20 desktop:pl-32 tablet:px-4 mobile:px-4 text-left"
        >
          <h4 className="text-left text-black mb-0">帳戶 Account</h4>
          <p className="text-black text-left">{me.email}</p>
          <p className="text-left">
            <Button type="link" onClick={logOut} className="pl-0">
              登出
            </Button>
          </p>
          <Divider />
          <h4 className="text-left text-black mb-0">付款 Account</h4>
          <p className="text-SKF-G-002">所有交易都受到安全加密保護。</p>
          <Radio.Group onChange={onRadioChange} value={radio}>
            <Space direction="vertical">
              <Radio value={1}>
                <Space>
                  綠界科技- 信用卡一次付清<img src={Pay}></img>
                </Space>
              </Radio>
              {/* <Radio value={2}>綠界科技- ATM虛擬帳號</Radio> */}
            </Space>
          </Radio.Group>
        </Col>
        <Col
          sm={{ span: 12 }}
          xs={{ span: 24 }}
          className="bg-SKF-G-000 pb-20 desktop:pr-32 desktop:pl-8 mobile:px-4 tablet:px-4"
        >
          <h4 className="text-left text-black mb-0">產品 Items</h4>
          <Form
            layout="vertical"
            form={form}
            onFinish={handlePay}
            onFinishFailed={handelPayError}
            scrollToFirstError={{ block: "center" }}
          >
            {carts &&
              carts.map((cart, index) => {
                return (
                  <Card
                    key={index}
                    title={
                      <Row gutter={16}>
                        <Col span={20} className="text-left">
                          <p className="whitespace-normal">{cart.groupTitle}</p>
                          <p className="font-normal whitespace-normal">
                            {cart.title}
                          </p>
                        </Col>
                        <Col span={4} className="text-left">
                          <p className="bg-SKF-Blue-001 text-white p-2 rounded-lg">
                            數量：{cart.amount}
                          </p>
                          <p className="mt-2 text-base">
                            NT${cart.amount * cart.price}
                          </p>
                        </Col>
                      </Row>
                    }
                    className="my-3 py-4 [&_.ant-card-head-wrapper]:items-start"
                    extra={<></>}
                  >
                    <Form.List
                      name={["detail", index]}
                      // initialValue={Array.from(Array(cart.amount), (_, x) => {
                      //   return { sourceId: cart.sourceId };
                      // })}
                    >
                      {(fields) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Row gutter={16} key={key}>
                              <Col span={24}>
                                <Space className="mb-4 w-full [&_.ant-space-item:first-child]:grow">
                                  <p className="text-left justify-between ">
                                    票券 {key + 1}-{cart.amount}
                                  </p>
                                  <Checkbox
                                    onChange={(checked) =>
                                      handleCheck(checked, index, key)
                                    }
                                  >
                                    帶入會員資料
                                  </Checkbox>
                                </Space>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  name={[name, "name"]}
                                  label="Name"
                                  rules={[{ required: true }]}
                                >
                                  <Input
                                    className="p-2"
                                    size="large"
                                    placeholder="請輸入持票人姓名"
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "gender"]}
                                  label="Gender"
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    options={genderOptions}
                                    size="large"
                                    className="[&_.ant-select-selector]:p-2 [&_.ant-select-arrow]:h-0 text-left"
                                    placeholder="請選擇持票人性別"
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "age"]}
                                  label="Age"
                                  rules={[{ required: true }]}
                                >
                                  <Input
                                    className="p-2"
                                    size="large"
                                    placeholder="請輸入持票人年齡"
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  name={[name, "phone"]}
                                  label="Mobile Phone"
                                  rules={[{ required: true }]}
                                >
                                  <Input
                                    className="p-2"
                                    size="large"
                                    placeholder="請輸入持票人手機號碼"
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  name={[name, "email"]}
                                  label="Email"
                                  rules={[
                                    { required: true },
                                    {
                                      type: "email",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="p-2"
                                    size="large"
                                    placeholder="請輸入持票人Email"
                                  ></Input>
                                </Form.Item>
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </Card>
                );
              })}
            <div className="fixed right-0 bottom-0 bg-white w-full min-h-28 border-solid border border-SKF-G-001 pt-4 pb-8 z-10">
              <Row>
                <Col span={24} className="mb-4">
                  <Form.Item>
                    <Button
                      type="primary"
                      className="bg-SKF-Blue-001 w-5/6 desktop:w-4/5"
                      // onClick={handlePay}
                      htmlType="submit"
                      loading={isPayLoading}
                    >
                      馬上付款 Pay
                    </Button>
                  </Form.Item>
                </Col>
                <Col
                  sm={{ span: 12 }}
                  xs={{ span: 24 }}
                  className="desktop:text-left mobile:text-center tablet:text-center"
                >
                  <p className="text-SKF-Blue-001 desktop:pl-36">
                    票券問題請聯繫：support@rftexp.com
                  </p>
                </Col>
                <Col
                  sm={{ span: 12 }}
                  xs={{ span: 24 }}
                  className="desktop:text-right mobile:text-center tablet:text-center desktop:pr-36"
                >
                  <p className="text-SKF-G-002">Skyline Film 保留所有權利</p>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CheckOut;
