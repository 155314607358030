// pageView

export const pageviewConfig = [
  {
    hitType: "pageview",
    page: "/home",
    title: "首頁(home)",
  },
  {
    hitType: "pageview",
    page: "/corporation",
    title: "與我們合作(corporation)",
  },
  {
    hitType: "pageview",
    page: "/about",
    title: "關於我們(about)",
  },
  {
    hitType: "pageview",
    page: "/activity",
    title: "電影活動(activity)",
  },
  {
    hitType: "pageview",
    page: "/checkout",
    title: "結帳(checkout)",
  },

  {
    hitType: "pageview",
    page: "/register",
    title: "註冊(register)",
  },
  {
    hitType: "pageview",
    page: "/member",
    title: "會員中心(member)",
  },
  {
    hitType: "pageview",
    page: "/login",
    title: "登入(login)",
  },
  {
    hitType: "pageview",
    page: "/reset",
    title: "重設密碼(reset)",
  },
];
