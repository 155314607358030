import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setIsChartOpen } from "store/slice/index";

import { useParams, useNavigate } from "react-router-dom";

import MapIcon from "assets/activity/map.png";
import Clock from "assets/activity/clock.png";
import Cart from "assets/activity/cartIcon.png";

import {
  Row,
  Col,
  Spin,
  Divider,
  message,
  Button,
  Input,
  Modal,
  Typography,
} from "antd";
import { ShoppingCartOutlined, UpOutlined } from "@ant-design/icons";

import { apiGetActivityContent, apiPutCarts, apiGetCarts } from "api/api";
import { convertTimeStamp } from "utility/functions/convertTimestamp";

import parse from "html-react-parser";

const Activity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { TextArea } = Input;
  const { Paragraph } = Typography;

  const [rowData, setRowData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  // 電影介紹的彈窗
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 電影介紹的內容
  const [ticketIntro, setTicketIntro] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    const res = await apiGetActivityContent({ id });
    const { content } = res.data;
    console.log("content", content);
    setRowData(content);
    try {
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    info_meta();
  }, [rowData]);

  const info_meta = () => {
    // const { country, city, line, remark } = rowData?.address;
    const { endDateTime, startDateTime, address } = rowData;
    return [
      {
        name: "地點",
        icon: MapIcon,
        content: (
          <a
            className="underline decoration-1 text-base"
            href={address?.link}
            target="_blank"
          >
            {address?.country}
            {address?.city}
            {address?.line}
            {!address?.remark.includes("undefined") && `（${address?.remark}）`}
          </a>
        ),
      },
      {
        name: "時間",
        icon: Clock,
        content: (
          <p className="text-base">
            {convertTimeStamp(startDateTime)}~{convertTimeStamp(endDateTime)}
          </p>
        ),
      },
    ];
  };

  const fetchCharts = async () => {
    try {
      const res = await apiGetCarts();
      return res.data.content.details;
    } catch (err) {
      console.log(err);
    }
  };

  const openCharts = async (item) => {
    if (sessionStorage._skyFilm_t && sessionStorage._skyFilm_t.length > 0) {
      try {
        // # 先取得購物車
        const currentCarts = await fetchCharts();

        // # 判斷傳進來的 item 是否已經存在於 currentCarts 中
        const isExist = currentCarts.some((cart) => cart.sourceId == item.id);

        // # 若已經存在，則將 currentCarts 中的該 item.amount + 1
        if (isExist) {
          currentCarts.map((cart) => {
            if (cart.sourceId == item.id) return cart.amount++;
          });
          await apiPutCarts(currentCarts);
        }
        // # 若是新的項目
        else {
          // 比對當前點擊的票券是在購物車裡面的哪一個
          // const toAddCart = currentCarts.filter(
          //   (cart) => cart.sourceId == item.id
          // );
          await apiPutCarts([
            // # 將原本其他存在陣列中的item也帶回
            ...currentCarts.filter((item) => item.sourceId !== item.id),
            {
              sourceId: item.id,
              // 購物車內的數量 + 1
              amount: 1,
            },
          ]);
        }
      } catch (error) {
        message.error(error.response.data.message);
      }
      setTimeout(() => {
        dispatch(setIsChartOpen(true));
      }, 0);
    } else navigate("/login");
  };

  // # 點擊圖片判斷是否售完，售完return，反之則打開購物車
  const handleClickImg = (item) => {
    if (item.remainQuantity == 0) return;
    // else openCharts(item);
    else {
      setIsModalOpen(true);
      setTicketIntro(item);
    }
  };

  // # 回到最上
  const backToTop = () => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  // # 觀察 scroll
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setIsScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div>
      <Spin
        spinning={true}
        size="large"
        className="[&_.ant-spin-dot-item]:bg-white"
      >
        {/* 頂部大圖 */}
        <Row className="h-full  px-2 max-w-[1440px] mx-auto">
          <Col
            span={24}
            className="h-[400px] desktop:h-[400px] rounded-lg w-full"
          >
            <picture className="h-400px] desktop:h-[400px]">
              <source
                media="(max-width: 400px)"
                srcSet={encodeURI(rowData.mobileCover)}
              />
              <img
                src={rowData.webCover}
                className="w-full h-[400px] desktop:h-[400px] object-cover rounded-3xl"
              />
            </picture>
          </Col>
        </Row>

        {/* 內容 */}
        <div>
          {/* 活動標題 */}
          <Row className="w-full pt-5 mobile:px-4 tablet:px-20 desktop:max-w-[1280px] mx-auto">
            <Col span={24}>
              <h4 className="border-b border-SKF-G-003 pb-8 desktop:text-3xl text-left d">
                {rowData.title}
              </h4>
            </Col>
          </Row>

          {/* 活動資訊 - 地點、日期、票價 */}
          <Row className="mb-10 mobile:px-4 tablet:px-20 desktop:max-w-[1280px] mx-auto">
            {info_meta().map((item) => (
              <Col
                sm={{ span: 8 }}
                xs={{ span: 24 }}
                className="text-left text-SKF-G-001"
              >
                <div className="flex items-center">
                  <img src={item.icon} className="scale-50" alt="" />
                  <h5 className="my-0">{item.name}</h5>
                </div>
                <p className="pl-4">{item.content}</p>
              </Col>
            ))}
          </Row>

          {/* 電影圖片 */}
          <Row className="mobile:px-2 tablet:px-20 desktop:max-w-[1280px] mx-auto">
            {rowData?.tickets?.map((item) => {
              return (
                <Col
                  key={item.id}
                  lg={{ span: 8 }}
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  className="mb-10 cursor-pointer transition ease-in-out delay-100 hover:opacity-75 duration-200  mobile:px-1 tablet:px-2" //hover:-translate-y-2
                >
                  <picture
                    className="h-[400px] desktop:h-[520px]"
                    key={item.id}
                    onClick={() => handleClickImg(item)}
                  >
                    <source
                      media="(max-width: 520px)"
                      srcSet={item.mobile_url}
                    />
                    <img
                      src={item.cover}
                      alt=""
                      className="w-full mobile:h-[184px] h-[310px] object-cover mobile:rounded-lg tablet:rounded-2xl desktop:rounded-3xl"
                    />
                  </picture>

                  {/* 售完顯示樣式 */}
                  {item.remainQuantity == 0 && (
                    <div
                      className="mobile:rounded-lg desktop:rounded-3xl mobile:h-[184px] desktop:h-[310px]"
                      style={{
                        position: "absolute",
                        width: "calc(100% - 16px)",
                        top: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center bottom",
                        backgroundImage:
                          "linear-gradient(to bottom right, rgba(0, 47, 75, .5), rgba(220, 66, 37, .5)), url('http://www.planwallpaper.com/static/images/Free-Wallpaper-Nature-Scenes.jpg')",
                        backgroundSize: "cover",
                      }}
                    >
                      <h2 className="mobile:mt-20 desktop:mt-36">SOLD OUT</h2>
                    </div>
                  )}

                  {/* 剩餘數量 !== 0時，顯示購物車icon */}
                  {item.remainQuantity !== 0 && (
                    <img
                      src={Cart}
                      className="rounded-md tablet:p-1 mobile:p-[2.5px] bg-white absolute tablet:top-3 tablet:right-5 mobile:top-1 mobile:right-3 mobile:scale-75"
                      onClick={() => openCharts(item)}
                      alt=""
                    ></img>
                  )}

                  {/* border-SKF-G-004 */}
                  <div
                    className="text-left"
                    onClick={() => handleClickImg(item)}
                  >
                    {/* 剩餘數量 !== 0時，顯示票價，若否則顯示售完 */}
                    <span
                      className={`bg-white text-black p-2 rounded-3xl absolute tablet:top-4 tablet:left-6 mobile:top-2 mobile:left-3 mobile:py-1 ${
                        item.remainQuantity == 0 ? "hidden" : "block"
                      }`}
                    >
                      {/* {item.remainQuantity == 0 ? "售完" : `NT＄${item.price}`} */}
                      NT＄{item.price}
                    </span>
                    {/* <p className="m-0 my-2 text-SKF-G-002">
                    {convertTimeStamp(item.startDateTime)}
                  </p> */}
                    <p className="text-SKF-G-002 text-14">{`${convertTimeStamp(
                      item.startDateTime,
                      "MM/DD ddd - HH:mm"
                    )}（opens at ${convertTimeStamp(
                      item.effectiveStartDateTime,
                      "HH:mm"
                    )}）`}</p>
                    <p className="m-0 ">{item.title}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
          {/* 編輯器內容 */}
          <Row className="pb-10 bg-SKF-G-005">
            <Col span={24} className="text-left tablet:px-20 mobile:px-4">
              <div className="desktop:max-w-[800px] tablet:max-w-[800px] mx-auto">
                <h4 className="font-bold">活動簡介</h4>
                {rowData?.content?.introduction && (
                  <p className="desktop:max-w-[800px] tablet:max-w-[800px] mx-auto">
                    {parse(`${rowData?.content?.introduction}`)}
                  </p>
                )}
              </div>
            </Col>
            <Col span={24} className="text-left tablet:px-20 mobile:px-4">
              <div className="desktop:max-w-[800px] tablet:max-w-[800px] mx-auto">
                <h4 className="font-bold">注意事項</h4>
                {rowData?.content?.notification && (
                  <div className="pl-4">
                    {parse(rowData?.content?.notification)}
                  </div>
                )}
              </div>
            </Col>
            <Divider />
            <Col span={24} className="text-left tablet:px-20 mobile:px-4">
              <div className="desktop:max-w-[800px] tablet:max-w-[800px] mx-auto">
                {rowData?.content?.summary && (
                  <p className="desktop:max-w-[800px] tablet:max-w-[800px] mx-auto">
                    {parse(`${rowData?.content?.summary}`)}
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <Button
            type="primary"
            onClick={backToTop}
            className={`bg-SKF-Blue-001 fixed desktop:right-10 desktop:bottom-10 tablet:right-5 tablet:bottom-10 mobile:right-5 mobile:bottom-5 z-10 ${
              scrollTop > 900 ? "block" : "hidden"
            }`}
            icon={<UpOutlined />}
          >
            商品列表 &nbsp;
          </Button>
        </div>
      </Spin>

      {/* 電影介紹彈窗 */}
      <Modal
        // title={`${t("activity.description")}`}
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        className="mobile:mx-auto mobile:w-11/12 [&_.ant-modal-title]:bg-SKF-G-004 [&_.ant-modal-title]:text-white [&_.ant-modal-close-x]:text-white [&_.ant-modal-content]:bg-SKF-G-004 [&_.ant-modal-close]:!top-1 [&_.ant-modal-close]:!left-1"
      >
        <Row>
          <Col
            key={ticketIntro.id}
            lg={{ span: 24 }}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            className="cursor-pointer transition ease-in-out delay-100 hover:opacity-75 duration-200  mobile:px-1 tablet:px-2" //hover:-translate-y-2
          >
            <picture
              className="h-[400px] desktop:h-[520px]"
              key={ticketIntro.id}
              // onClick={() => handleClickImg(item)}
            >
              <source
                media="(max-width: 520px)"
                srcSet={ticketIntro.mobile_url}
              />
              <img
                src={ticketIntro.cover}
                alt=""
                className="w-full mobile:h-[184px] h-[310px] object-cover mobile:rounded-lg tablet:rounded-2xl desktop:rounded-3xl"
              />
            </picture>
            <Divider className="border-SKF-G-003 my-3" />
            {/* 售完顯示樣式 */}
            {ticketIntro.remainQuantity == 0 && (
              <div
                className="mobile:rounded-lg desktop:rounded-3xl mobile:h-[184px] desktop:h-[310px]"
                style={{
                  position: "absolute",
                  width: "calc(100% - 16px)",
                  top: 0,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center bottom",
                  backgroundImage:
                    "linear-gradient(to bottom right, rgba(0, 47, 75, .5), rgba(220, 66, 37, .5)), url('http://www.planwallpaper.com/static/images/Free-Wallpaper-Nature-Scenes.jpg')",
                  backgroundSize: "cover",
                }}
              >
                <h2 className="mobile:mt-20 desktop:mt-36">SOLD OUT</h2>
              </div>
            )}

            {/* 剩餘數量 !== 0時，顯示購物車icon */}
            {ticketIntro.remainQuantity !== 0 && (
              <img
                src={Cart}
                className="rounded-md tablet:p-1 mobile:p-[2.5px] bg-white absolute tablet:top-3 tablet:right-5 mobile:top-1 mobile:right-3 mobile:scale-75"
                onClick={() => openCharts(ticketIntro)}
                alt=""
              ></img>
            )}

            {/* border-SKF-G-004 */}
            <div
              className="text-left"
              onClick={() => handleClickImg(ticketIntro)}
            >
              {/* 剩餘數量 !== 0時，顯示票價，若否則顯示售完 */}
              <span
                className={`bg-white text-black p-2 rounded-3xl absolute tablet:top-4 tablet:left-6 mobile:top-2 mobile:left-3 mobile:py-1 ${
                  ticketIntro.remainQuantity == 0 ? "hidden" : "block"
                }`}
              >
                {/* {item.remainQuantity == 0 ? "售完" : `NT＄${item.price}`} */}
                NT＄{ticketIntro.price}
              </span>
              {/* <p className="m-0 my-2 text-SKF-G-002">
                {convertTimeStamp(ticketIntro.startDateTime)}
              </p> */}
              <p className="text-SKF-G-002 text-14">{`${convertTimeStamp(
                ticketIntro.startDateTime,
                "MM/DD ddd - HH:mm"
              )}（opens at ${convertTimeStamp(
                ticketIntro.effectiveStartDateTime,
                "HH:mm"
              )}）`}</p>
              <p className="m-0 mb-5 text-white text-xl">{ticketIntro.title}</p>
            </div>
            <Paragraph
              className="text-SKF-G-002"
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: "展開",
              }}
            >
              {ticketIntro.introduction}
            </Paragraph>
          </Col>
        </Row>
        {/* <TextArea
          value={ticketIntro.introduction}
          autoSize={true}
          className="border-none bg-SKF-G-004 text-SKF-G-002 pl-0"
        ></TextArea> */}
      </Modal>
    </div>
  );
};

export default Activity;
